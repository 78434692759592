<template>
      <div class="view">
          <router-view :key="$route.params" />
      </div>
</template>

<script>
export default {
    name: "LoginLayout",
};
</script>
