<template>
    <nav class="toolbar">
        <ul>
            <router-link
                v-for="page in pages"
                :to="page.url"
                :key="page.url"
                v-slot="{ href, route, navigate }"
                custom
            >
                <li :class="[route.name === $route.name && 'active']">
                    <a :href="href" @click="navigate">
                        <component :is="page.icon"></component>
                        {{ page.name }}
                    </a>
                </li>
            </router-link>
        </ul>
    </nav>
</template>

<script>
import iconWeekMenu from "@/assets/icons/icon-weekmenu.svg?inline";
import iconRecipe from "@/assets/icons/icon-recipe.svg?inline";
import iconDashboard from "@/assets/icons/icon-dashboard.svg?inline";
import iconMyPg from "@/assets/icons/icon-my-pg.svg?inline";
import iconMore from "@/assets/icons/icon-more.svg?inline";

export default {
    components: {
        iconWeekMenu,
        iconRecipe,
        iconDashboard,
        iconMyPg,
        iconMore
    },

    data() {
        return {
            pages: [
                {
                    url: "/week-menu",
                    name: this.$t("Weekmenus"),
                    icon: "iconWeekMenu"
                },
                {
                    url: "/recepten",
                    name: this.$t("Recipes"),
                    icon: "iconRecipe"
                },
                {
                    url: "/",
                    name: this.$t("Start"),
                    icon: "iconDashboard"
                },
                {
                    url: "/my-pg",
                    name: this.$t("My PG"),
                    icon: "iconMyPg"
                },
                {
                    url: "/cms-menu",
                    name: this.$t("More"),
                    icon: "iconMore"
                }
            ]
        };
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
