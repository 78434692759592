import HTTPClient from "@/lib/HTTPClient";

import DayMenu from "@/models/DayMenu/DayMenu";
import Recipe from "@/models/Recipe";

const Dasboard = {};

Dasboard.GetDashboard = async function() {
    let resp = await HTTPClient.Get("/v3/dashboard");

    if (!resp || resp.status != 200 || !resp.data) {
        return null;
    }

    let { day_menus, planned_day_menus, recipes } = resp.data.data;

    day_menus = day_menus.map((i) => new DayMenu(i));
    planned_day_menus = planned_day_menus.map((i) => new DayMenu(i));
    recipes = recipes.map((i) => new Recipe(i));

    return { day_menus, planned_day_menus, recipes };
};

export default Dasboard;
