<template>
    <aside>
        <template v-if="pages.length > 0 || moreMenuPages.length > 0">
            <div class="logo">
                <router-link to="/">
                    <SidebarLogo />
                </router-link>
            </div>
            <ul>
                <menu-item
                    v-for="page in pages"
                    :key="page.getId()"
                    :item="page"
                />
                <li :class="`${menuCollapsed ? '-open' : ''}`">
                    <a href="#" @click.prevent="menuCollapsed = !menuCollapsed">
                        {{ $t("More") }}
                        <icon-right />
                    </a>
                    <ul class="submenu">
                        <menu-item
                            v-for="page in moreMenuPages"
                            :key="page.getId()"
                            :item="page"
                        />
                    </ul>
                </li>
            </ul>
        </template>
    </aside>
</template>

<script>
import MenuApi from "@/lib/api/Menu";

import MenuItem from "@/components/MenuItem";

import SidebarLogo from "@/assets/images/logo.svg?inline";
import iconRight from "@/assets/icons/icon-chevron-right.svg?inline";

export default {
    components: {
        SidebarLogo,
        MenuItem,
        iconRight
    },
    data() {
        return {
            menuCollapsed: false,
            pages: [],
            moreMenuPages: []
        };
    },
    async mounted() {
        this.menuCollapsed = this.$route.fullPath.includes("pagina");
        this.pages = await MenuApi.GetListByPosition("head");
        this.moreMenuPages = await MenuApi.GetListByPosition("footer");
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
