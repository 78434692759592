<template>
    <main class="page-body">
        <sidebar />
        <div :class="`view ${isLoading ? 'app-loading' : ''}`">
            <router-view :key="$route.params" />
        </div>
    </main>
    <toolbar />
</template>

<script>
import {mapGetters} from "vuex";
import Sidebar from "@/components/Sidebar";
import Toolbar from "@/components/Toolbar";

export default {
    components: {
        Sidebar,
        Toolbar,
    },
    computed: {
        ...mapGetters("loader", ["isLoading"]),
    },
    name: "AppLayout",
};
</script>
