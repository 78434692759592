<template>
    <div class="dashboard-view" v-if="getUser">
        <Header
            :title="`${greetingForm} ${getUser.getFirstName()}`"
            :subtitle="getDate">
        </Header>

        <div class="container">
            <div class="dashboard-up-next">
                <up-next-slider
                    :recipes="recipes" />
            </div>

            <div class="dashboard-recipes" v-if="day_menus">
                <week-menu-switcher
                    :planned-menus="planned_day_menus"
                    :day-menus="day_menus"
                    :relative-days="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardApi from "@/lib/api/Dashboard";
import Header from "@/components/Header";
import UpNextSlider from "@/components/UpNextSlider";
import WeekMenuSwitcher from "@/components/WeekMenuSwitcher";
import DateTime from "@/lib/misc/DateTime";

export default {
    name: "Dashboard",
    components: {
        Header,
        UpNextSlider,
        WeekMenuSwitcher
    },
    data() {
        return {
            day_menus: null,
            planned_day_menus: null,
            recipes: null,
            greetingForm: ""
        };
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        getDate: () => DateTime.ConvertDateToHumanReadable(new Date())
    },
    async mounted() {
        let currentDate = new Date();
        let hours = currentDate.getHours();

        if (hours < 6) {
            this.greetingForm = this.$t("Good night");
        } else if (hours < 12) {
            this.greetingForm = this.$t("Good morning");
        } else if (hours < 18) {
            this.greetingForm = this.$t("Good afternoon");
        } else {
            this.greetingForm = this.$t("Good evening");
        }

        // ! PRO-526
        let dashboard = await DashboardApi.GetDashboard();

        if (!dashboard) {
            return;
        }

        this.day_menus = dashboard.day_menus;
        this.planned_day_menus = dashboard.planned_day_menus;

        // ! PRO-528
        this.recipes = dashboard.recipes;
    }
};
</script>

<style lang="scss">
@import "index";
</style>
